







import SelectionCompany from "@/components/SelectionCompany.vue";

export default {
    name: "Management",
    components: { SelectionCompany },
    // mounted() {
    //     this.getCompanies()
    // },
    // methods: {
    // showAddChargingStation() {
    //     this.$api.get('/chargingStations/unassigned')
    //         .then((response) => {
    //             let unassigned = [{ value: null, text: 'Bitte wählen' }]
    //             for (let station of response.data) {
    //                 unassigned.push({ value: station.id, text: station.name })
    //             }
    //             this.unassignedStations =  unassigned
    //             this.$bvModal.show('add-charging-station-modal')
    //             // this.activeCompany = this.companies[0] ? this.companies[0].id : null
    //         })
    // },
    // addChargingStation(chargingStationId) {
    //     this.selectedStation = null
    //     this.$api.patch(`chargingStation/${chargingStationId}/assign/${this.activeCompany}`)
    //         .then(() => {
    //             this.$refs.chargingStationsTable.refresh()
    //             this.$bvToast.toast(`Die Ladestation wurde zugeordnet`, {
    //                 title: 'Ladestation zugeordnet',
    //                 autoHideDelay: 3000,
    //                 variant: 'success',
    //                 solid: true
    //             })
    //         })
    // },
    // deleteChargingStation(chargerId) {
    //     this.$bvModal.msgBoxConfirm('Möchten Sie die Zuordnung entfernen?')
    //         .then(value => {
    //             if(value) {
    //                 this.$api.patch(`/chargingStation/${chargerId}/removeAssignment`)
    //                 .then(() => {
    //                     this.$refs.chargingStationsTable.refresh()
    //                     this.$bvToast.toast(`Die Zuordnung wurde entfernt`, {
    //                         title: 'Ladestation entfernt',
    //                         autoHideDelay: 3000,
    //                         variant: 'success',
    //                         solid: true
    //                     })
    //                 })
    //             }
    //         })
    // },
    // addTag() {
    //     this.$bvModal.hide('add-tag-modal')
    //
    //
    //     this.$api.post(`tag/add/${this.activeCompany}`, {
    //         id: this.tagForm.id,
    //         name: this.tagForm.name,
    //         active: this.tagForm.active
    //     })
    //         .then(() => {
    //             this.clearTagForm()
    //             this.$refs.tagsTable.refresh()
    //             this.$bvToast.toast(`Der Tag wurde hinzugefügt`, {
    //                 title: 'Tag hinzugefügt',
    //                 autoHideDelay: 3000,
    //                 variant: 'success',
    //                 solid: true
    //             })
    //         })
    // },
    // deleteTag(tagId) {
    //     this.$bvModal.msgBoxConfirm(`Möchten Sie den Tag "${tagId}" wirklich entfernen?`)
    //         .then(value => {
    //             if(value) {
    //                 this.$api.delete(`/tag/${encodeURIComponent(tagId)}/remove`)
    //                     .then(() => {
    //                         this.$refs.tagsTable.refresh()
    //                         this.$bvToast.toast(`Der Tag wurde entfernt`, {
    //                             title: 'Tag entfernt',
    //                             autoHideDelay: 3000,
    //                             variant: 'success',
    //                             solid: true
    //                         })
    //                     })
    //             }
    //         })
    // },
    // setTag(tagId, status) {
    //     this.$api.patch(`/tag/${encodeURIComponent(tagId)}/active/${!status}`)
    //         .then(() => {
    //             this.$refs.tagsTable.refresh()
    //             this.$bvToast.toast(`Der Tag wurde ${status ? 'inaktiv' : 'aktiv'} gesetzt`, {
    //                 title: 'Tag geändert',
    //                 autoHideDelay: 3000,
    //                 variant: 'success',
    //                 solid: true
    //             })
    //         })
    // },
    // clearTagForm() {
    //   this.tagForm = {
    //       name: '',
    //       id: '',
    //       active: true
    //     }
    // },
    // getCompanies() {
    //     this.$api.get('/companies')
    //     .then((response) => {
    //         this.companies = response.data
    //         this.activeCompany = this.companies[0] ? this.companies[0].id : null
    //     })
    // },
    // chargingStationsProvider() {
    //     const promise = this.$api.get(`/chargingStations/${this.activeCompany}`)
    //     // Must return a promise that resolves to an array of items
    //     return promise.then(response => {
    //         // Pluck the array of items off our axios response
    //         const items = response.data
    //         // Must return an array of items or an empty array if an error occurred
    //         return items || []
    //     })
    // },
    // tagsProvider() {
    //     const promise = this.$api.get(`/tags/${this.activeCompany}`)
    //     // Must return a promise that resolves to an array of items
    //     return promise.then(response => {
    //         // Pluck the array of items off our axios response
    //         const items = response.data
    //         // Must return an array of items or an empty array if an error occurred
    //         return items || []
    //     })
    // },
    // changeCompany(companyId) {
    //     this.activeCompany = companyId
    //     this.$refs.chargingStationsTable.refresh()
    //     this.$refs.tagsTable.refresh()
    // }
    // },
    // data() {
    //     return {
    //         selectedStation: null,
    //         unassignedStations: [],
    //         tagForm: {
    //             name: '',
    //             id: '',
    //             active: true
    //         }
    //     }
    // },
}
